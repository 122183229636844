import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BosContext from '../context/private/bosContext';
import PrivateContext from '../context/private/privateContext';
import { Container, Flex } from '../styles/Styles';
import Header from './Header';
import Layout from './layout/Index';
import { timeToYYYYMMDD } from '../utils/tools';
import dictionary from '../locals/my-bos.json';

import config from '../config';
import Pagination from 'react-responsive-pagination';
import logo from '../assets/images/thumbnail-updates.png';
import NotFoundImage from '../assets/images/not-found.png';
import AccountContext from '../context/private/accountContext';

const ContentContainer = ({ state, classOptional = '' }: any): JSX.Element => {
  const [cleanedContent, setCleanedContent] = useState('');

  useEffect(() => {
    if (state && state?.content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(state?.content, 'text/html');
      const scripts = doc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        Array.from(script.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(script.innerHTML));
        document.body.appendChild(newScript);
        script.remove();
      });

      setCleanedContent(doc.body.innerHTML);
    }
  }, [state]);

  return (
    <Layout className={`parent ${classOptional ? classOptional : ''}`}>
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title={dictionary['PAGE-title']} />

            {state ? (
              <div className="explorer-body">
                <div className="explorer-content" style={{ gap: 0 }}>
                  {state?.title ? <h4>{state?.title}</h4> : null}
                  <div
                    className="content notification-holder"
                    dangerouslySetInnerHTML={{
                      __html: cleanedContent
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="explorer-body">
                <div className="explorer-content" style={{ gap: 0 }}>
                  <h4>
                    <div className="skeleton-element-item  ck-editor-title skeleton-box"></div>
                  </h4>
                  <div className="skeleton-element-item ck-editor-content skeleton-box"></div>
                </div>
              </div>
            )}
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};
const ContentContainer2 = ({ state, classOptional = '' }: any): JSX.Element => {
  return (
    <Layout className={`parent ${classOptional ? classOptional : ''}`}>
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title={dictionary['PAGE-title']} />
            {state ? (
              <div className="explorer-body">
                <div className="explorer-content" style={{ gap: 0 }}>
                  <div
                    className="content notification-holder"
                    dangerouslySetInnerHTML={{
                      __html: state
                    }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="explorer-body">
                <div className="explorer-content" style={{ gap: 0 }}>
                  <h4>
                    <div className="skeleton-element-item  ck-editor-title skeleton-box"></div>
                  </h4>
                  <div className="skeleton-element-item ck-editor-content skeleton-box"></div>
                </div>
              </div>
            )}
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

const ContentContainerUpdates = ({ state }: any): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [currentPage, setCurrentPage] = useState<any>(1);

  const { loadUpdatesPage } = useContext(BosContext);

  useEffect(() => {
    // setUpdateTimer(+new Date)
    loadUpdatesPage(currentPage);
  }, [currentPage]);

  const [cleanedContent, setCleanedContent] = useState('');

  useEffect(() => {
    if (modalDescription) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(modalDescription, 'text/html');
      const scripts = doc.getElementsByTagName('script');

      Array.from(scripts).forEach((script) => {
        const newScript = document.createElement('script');
        Array.from(script.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(script.innerHTML));
        document.body.appendChild(newScript);
        script.remove();
      });

      setCleanedContent(doc.body.innerHTML);
    }
  }, [modalDescription]);

  return (
    <Layout className="parent">
      <Container
        x={'center'}
        marginT={1}
        className="container container-boscrew"
      >
        <Flex gap={1.7} x={'center'} y={'start'} className="flex-boscrew">
          <div className="boscrew" style={{ width: '100%' }}>
            <Header title={dictionary['PAGE-title']} />

            <>
              {modalIsOpen && (
                <div
                  className="blur-bg"
                  onClick={() => setModalIsOpen(false)}
                ></div>
              )}

              {modalIsOpen && (
                <div className="mod">
                  <div className="mod-border">
                    <div className="mod-box">
                      <p className="mod-box-p">{modalTitle}</p>
                      <button
                        className="mod-box-btn"
                        onClick={() => setModalIsOpen(false)}
                      >
                        X
                      </button>
                    </div>
                    <div className="mod-inside">
                      <div className="mod-inside-body">
                        <p
                          className="modal-description"
                          dangerouslySetInnerHTML={{ __html: cleanedContent }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="myt-body">
                {state?.msg?.length === 0 ? (
                  <img src={NotFoundImage} />
                ) : state && state?.msg?.length > 0 ? (
                  state?.msg?.map((item: any, index: number) => (
                    <div key={index} className="myt-body-border">
                      <div className="myt-body-border-image">
                        <img
                          src={item?.image ? config.server + item?.image : logo}
                          alt="nature"
                        />
                      </div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div className="myt-body-header-t">
                              {item?.title}
                            </div>

                            <div className="myt-body-r">
                              <div className="myt-body-r-box">
                                <div className="myt-body-r-box-title">Date</div>
                                <div className="myt-body-r-box-val">
                                  {timeToYYYYMMDD(+new Date(item?.created_at))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <p
                            className="myt-body-r-box-p"
                            style={{ fontSize: '17px' }}
                            dangerouslySetInnerHTML={{
                              __html: item?.short_content
                            }}
                          ></p>
                        </div>
                        <div
                          className="myt-body-r-box-grid"
                          style={{ gridTemplateColumns: '1fr' }}
                        >
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn"
                              onClick={() => {
                                setModalIsOpen(true);
                                window.scrollTo(0, 0);
                                setModalDescription(item?.content);
                                setModalTitle(item?.title);
                              }}
                            >
                              Read More...
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myt-body-border">
                      <div className="myt-body-border-image skeleton-box"></div>
                      <div className="myt-body-detail">
                        <div>
                          <div className="myt-body-header">
                            <div
                              className="myt-body-header-t skeleton-box"
                              style={{ width: '30%' }}
                            ></div>
                            <div className="myt-body-r">
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                              <div
                                className="myt-body-r-box skeleton-box"
                                style={{ width: '45px' }}
                              ></div>
                            </div>
                          </div>
                          <p className="myt-body-r-box-p">
                            <span
                              className="skeleton-box"
                              style={{ width: '80%' }}
                            ></span>
                            <span
                              className="skeleton-box"
                              style={{ width: '60%' }}
                            ></span>
                          </p>
                        </div>
                        <div className="myt-body-r-box-grid">
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                            <div
                              className="myt-body-r-box-flex skeleton-box"
                              style={{
                                width: '60px',
                                backgroundColor: '#f6f6f6'
                              }}
                            ></div>
                          </div>
                          <div className="myt-body-r-box-r">
                            <button
                              className="myt-body-r-box-r-btn skeleton-box"
                              style={{ backgroundColor: '#DDDBDD' }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {state?.total_page > 0 ? (
                <div
                  className="island-list-pagination"
                  style={{ marginTop: '1rem' }}
                >
                  <Pagination
                    className="pagination"
                    total={state?.total_page}
                    current={state?.current_page}
                    maxWidth={300}
                    a11yActiveLabel=""
                    previousLabel=" "
                    nextLabel=" "
                    renderNav={false}
                    ariaPreviousLabel={'Prev'}
                    ariaNextLabel={'Next'}
                    onPageChange={(page: any) => setCurrentPage(page)}
                  />
                </div>
              ) : null}
            </>
          </div>
        </Flex>
      </Container>
    </Layout>
  );
};

const CKEditorContent = ({ type }: any): JSX.Element => {
  const { flowState } = useContext(PrivateContext);
  const {
    socialPageData,
    loadSocialPage,
    academyPageData,
    loadAcademyPage,
    lifestylePageData,
    loadLifestylePage,
    eventsPageData,
    loadEventsPage,
    shopPageData,
    loadShopPage,
    charityPageData,
    loadCharityPage,
    helpdeskPageData,
    loadHelpdeskPage,
    tutorialsPageData,
    loadTutorialsPage,
    updatesPageData,
    loadUpdatesPage,
    aboutPageData,
    loadAboutPage,
    offshorePageData,
    loadOffshorePage,
    debitcardPageData,
    loadDebitcardPage,
    dashboardFaqPageData,
    loadDashboardFaqPage,
    bosFaqPageData,
    loadBosFaqPage,
    bosVideoPageData,
    loadBosVideoPage,
    dashboardTutorialsPageData,
    loadDashboardTutorialsPage,
    cryptoTutorialsPageData,
    loadCryptoTutorialsPage,
    bosServicePageData,
    loadBosServicePage,
    bosMeetingPageData,
    loadBosMeetingPage,
    bosQAPageData,
    loadBosQAPage,
    bosWebinarsPageData,
    loadBosWebinarsPage,
    bosImblPageData,
    loadBosImblPage,
    bosRecommendationsPageData,
    loadBosRecommendationsPage,
    bosStrategySessionPageData,
    loadBosStrategySessionPage,
    bosNevisPageData,
    loadBosNevisPage,
    bosNevisRegistrationPageData,
    loadBosNevisRegistrationPage,
    bosStoriesPageData,
    loadBosStoriesPage,
    bosWithdrawPageData,
    loadBosWithdrawPage,
    bosOffshoreBankingPageData,
    loadBosOffshoreBankingPage,
    bosCayeePageData,
    loadBosCayeePage,
    bosChasePageData,
    loadBosMorganPage,
    loadBosChasePage,
    bosMyStrategyPageData,
    loadBosMyStrategyPage,
    bosWealthBlueprintPageData,
    loadBosWealthBlueprintPage,
    bosTreasuryStrategyPageData,
    loadBosTreasuryStrategyPage,
    bosBeaconsPageData,
    loadBosBeaconsPage,
    bosDepositTutorialPageData,
    loadBosDepositTutorialPage,
    loadTradingIslandTransitionPage,
    bosMorganPageData,
    tradingIslandTransitionPageData,
    bosCryptoDepositPageData,
    loadBosCryptoDepositPage,
    bosBankDepositPageData,
    loadBosBankDepositPage
  } = useContext(BosContext);

  const navigate = useNavigate();
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    switch (type) {
      case 1:
        loadSocialPage();
        break;
      case 2:
        loadAcademyPage();
        break;
      case 3:
        loadLifestylePage();
        break;
      case 4:
        loadEventsPage();
        break;
      case 5:
        loadShopPage();
        break;
      case 6:
        loadCharityPage();
        break;
      case 7:
        loadHelpdeskPage();
        break;
      case 8:
        loadTutorialsPage();
        break;
      case 9:
        loadUpdatesPage(1);
        break;
      case 10:
        loadAboutPage();
        break;
      case 11:
        loadOffshorePage();
        break;
      case 12:
        loadDebitcardPage();
        break;
      case 13:
        loadDashboardFaqPage();
        break;
      case 14:
        loadBosFaqPage();
        break;
      case 15:
        loadBosVideoPage();
        break;
      case 16:
        loadDashboardTutorialsPage();
        break;
      case 17:
        loadCryptoTutorialsPage();
        break;
      case 18:
        loadBosServicePage();
        break;
      case 19:
        loadBosMeetingPage();
        break;
      case 20:
        loadBosQAPage();
        break;
      case 21:
        loadBosWebinarsPage();
        break;
      case 22:
        loadBosImblPage();
        break;
      case 23:
        loadBosRecommendationsPage();
        break;
      case 24:
        loadBosStrategySessionPage();
        break;
      case 25:
        loadBosNevisPage();
        break;
      case 26:
        loadBosNevisRegistrationPage();
        break;
      case 27:
        loadBosStoriesPage();
        break;
      case 28:
        loadBosWithdrawPage();
        break;
      case 29:
        loadBosOffshoreBankingPage();
        break;
      case 30:
        loadBosCayeePage();
        break;
      case 31:
        loadBosChasePage();
        break;
      case 32:
        loadBosWealthBlueprintPage();
        break;
      case 33:
        loadBosTreasuryStrategyPage();
        break;
      case 34:
        loadBosMyStrategyPage();
        break;
      case 35:
        loadBosBeaconsPage();
        break;
      case 36:
        loadBosDepositTutorialPage();
        break;
      case 37:
        loadBosCryptoDepositPage();
        break;
      case 38:
        loadBosBankDepositPage();
        break;
      case 39:
        loadBosMorganPage();
        break;
      case 40:
        loadTradingIslandTransitionPage();
        break;

      default:
        break;
    }
  }, [type]);
  // return socialPageData ? <ContentContainer title={socialPageData?.title} content={socialPageData?.content} /> : <Loader />

  const { userInfo } = useContext(AccountContext);

  if (flowState?.step === 17 && userInfo && userInfo?.membershipStatus !== 2) {
    switch (type) {
      case 1:
        return <ContentContainer state={socialPageData} />;

      case 2:
        return <ContentContainer state={academyPageData} />;

      case 3:
        return <ContentContainer state={lifestylePageData} />;

      case 4:
        return <ContentContainer state={eventsPageData} />;

      case 5:
        return <ContentContainer state={shopPageData} />;

      case 6:
        return <ContentContainer state={charityPageData} />;

      case 7:
        return <ContentContainer state={helpdeskPageData} />;

      case 8:
        return <ContentContainer state={tutorialsPageData} />;

      case 9:
        return <ContentContainerUpdates state={updatesPageData} />;

      case 10:
        return <ContentContainer state={aboutPageData} />;

      case 11:
        return <ContentContainer state={offshorePageData} />;

      case 12:
        return <ContentContainer state={debitcardPageData} />;

      case 13:
        return <ContentContainer state={dashboardFaqPageData} />;

      case 14:
        return <ContentContainer state={bosFaqPageData} />;

      case 15:
        return <ContentContainer state={bosVideoPageData} />;

      case 16:
        return <ContentContainer state={dashboardTutorialsPageData} />;

      case 17:
        return <ContentContainer state={cryptoTutorialsPageData} />;

      case 18:
        return <ContentContainer state={bosServicePageData} />;

      case 19:
        return <ContentContainer state={bosMeetingPageData} />;

      case 20:
        return <ContentContainer state={bosQAPageData} />;

      case 21:
        return <ContentContainer state={bosWebinarsPageData} />;

      case 22:
        return <ContentContainer state={bosImblPageData} />;

      case 23:
        return <ContentContainer state={bosRecommendationsPageData} />;

      case 24:
        return <ContentContainer state={bosStrategySessionPageData} />;

      case 25:
        return <ContentContainer state={bosNevisPageData} />;

      case 26:
        return <ContentContainer state={bosNevisRegistrationPageData} />;

      case 27:
        return <ContentContainer state={bosStoriesPageData} />;

      case 28:
        return (
          <ContentContainer
            state={bosWithdrawPageData}
            classOptional="withdraw-hide"
          />
        );

      case 29:
        return (
          <ContentContainer
            state={bosOffshoreBankingPageData}
            classOptional="offshore-banking-hide"
          />
        );

      case 30:
        return (
          <ContentContainer
            state={bosCayeePageData}
            classOptional="cayee-hide"
          />
        );

      case 31:
        return (
          <ContentContainer
            state={bosChasePageData}
            classOptional="chase-hide"
          />
        );

      case 32:
        return <ContentContainer2 state={bosWealthBlueprintPageData} />;

      case 33:
        return <ContentContainer2 state={bosTreasuryStrategyPageData} />;

      case 34:
        return <ContentContainer2 state={bosMyStrategyPageData} />;

      case 35:
        return <ContentContainer2 state={bosBeaconsPageData} />;

      case 36:
        return <ContentContainer state={bosDepositTutorialPageData} />;

      case 37:
        return <ContentContainer state={bosCryptoDepositPageData} />;

      case 38:
        return <ContentContainer state={bosBankDepositPageData} />;
      case 39:
        return (
          <ContentContainer
            state={bosMorganPageData}
            // classOptional="jpmorgan-hide"
          />
        );
      case 40:
        return (
          <ContentContainer
            state={tradingIslandTransitionPageData}
            // classOptional="jpmorgan-hide"
          />
        );

      default:
        return <></>;
    }
  } else {
    return <>{userInfo && navigate('/')}</>;
  }

  return <></>;
};

export default CKEditorContent;
